import {
    IonBackButton, IonBadge, IonButton,
    IonButtons, IonCheckbox, IonCol,
    IonContent,
    IonGrid,
    IonHeader, IonIcon, IonImg, IonItem, IonItemDivider,
    IonItemSliding, IonLabel, IonList, IonMenu, IonMenuButton,
    IonPage, IonRouterOutlet, IonRow, IonSearchbar, IonSelect, IonSelectOption, IonSlide, IonSlides, IonText,
    IonTitle,
    IonToolbar
} from '@ionic/react';

import {menuController} from '@ionic/core';

import './ProductSubCategory.scss';
import './ProductSearch.scss';
import React, {useState} from "react";
import {
    addOutline, bagOutline,
    cartOutline,
    closeOutline,
    searchOutline,
    trash,
    trashBinOutline,
    trashOutline
} from "ionicons/icons";
import {Link, useHistory} from "react-router-dom";


const ProductSearch: React.FC = () => {

    const [searchText, setSearchText] = useState('');
    let history = useHistory();

    return (
        <>
            <IonPage>
                <IonHeader className="ion-no-border search-header main-header">
                    <IonToolbar>
                        <IonSearchbar value={searchText} onIonChange={e => setSearchText(e.detail.value!)}
                                      showCancelButton="focus"></IonSearchbar>
                    </IonToolbar>
                </IonHeader>

                <IonContent className="sub-category-container">
                    <IonRow>
                        <IonCol size="6" className="product small-size ion-margin-bottom" onClick={()=>{
                            history.push('/product-detail')
                        }}>
                            <div className="product-image">
                                <IonImg src={require("../assets/images/core-img/image-5.png").default}/>
                            </div>

                            <div className="product-description">
                                <h6 className="ion-text-uppercase">regular fit shirt with camp color</h6>
                                <h6 className="ion-text-uppercase">$ 45.5</h6>
                                <div className="product-colors d-flex">
                                    <span style={{background: "black"}} className="active"></span>
                                    <span style={{background: "maroon"}}></span>
                                    <span style={{background: "brown"}}></span>
                                </div>
                            </div>
                        </IonCol>

                        <IonCol size="6" className="product small-size ion-margin-bottom">
                            <div className="product-image">
                                <IonImg src={require("../assets/images/core-img/image-2.png").default}/>
                            </div>

                            <div className="product-description">
                                <h6 className="ion-text-uppercase">regular fit shirt with camp color</h6>
                                <h6 className="ion-text-uppercase">$ 45.5</h6>
                                <div className="product-colors d-flex">
                                    <span style={{background: "black"}} className="active"></span>
                                    <span style={{background: "maroon"}}></span>
                                    <span style={{background: "brown"}}></span>
                                </div>
                            </div>
                        </IonCol>

                        <IonCol size="6" className="product small-size ion-margin-bottom">
                            <div className="product-image">
                                <IonImg src={require("../assets/images/core-img/image-6.png").default}/>
                            </div>

                            <div className="product-description">
                                <h6 className="ion-text-uppercase">regular fit shirt with camp color</h6>
                                <h6 className="ion-text-uppercase">$ 45.5</h6>

                                <div className="product-colors d-flex">
                                    <span style={{background: "black"}} className="active"></span>
                                    <span style={{background: "maroon"}}></span>
                                    <span style={{background: "brown"}}></span>
                                </div>

                                <h6 className="ion-text-uppercase">join life</h6>
                            </div>
                        </IonCol>

                        <IonCol size="6" className="product small-size ion-margin-bottom">
                            <div className="product-image">
                                <IonImg src={require("../assets/images/core-img/image-7.png").default}/>
                            </div>

                            <div className="product-description">
                                <h6 className="ion-text-uppercase">regular fit shirt with camp color</h6>
                                <h6 className="ion-text-uppercase">$ 45.5</h6>
                                <div className="product-colors d-flex">
                                    <span style={{background: "black"}} className="active"></span>
                                    <span style={{background: "maroon"}}></span>
                                    <span style={{background: "brown"}}></span>
                                </div>

                                <h6 className="ion-text-uppercase">join life</h6>
                            </div>
                        </IonCol>

                        <IonCol size="6" className="product small-size ion-margin-bottom">
                            <div className="product-image">
                                <IonImg src={require("../assets/images/core-img/image-8.png").default}/>
                            </div>

                            <div className="product-description">
                                <h6 className="ion-text-uppercase">regular fit shirt with camp color</h6>
                                <h6 className="ion-text-uppercase">$ 45.5</h6>
                                <div className="product-colors d-flex">
                                    <span style={{background: "black"}} className="active"></span>
                                    <span style={{background: "maroon"}}></span>
                                    <span style={{background: "brown"}}></span>
                                </div>
                            </div>
                        </IonCol>

                        <IonCol size="6" className="product small-size ion-margin-bottom">
                            <div className="product-image">
                                <IonImg src={require("../assets/images/core-img/image-9.png").default}/>
                            </div>

                            <div className="product-description">
                                <h6 className="ion-text-uppercase">regular fit shirt with camp color</h6>
                                <h6 className="ion-text-uppercase">$ 45.5</h6>
                                <div className="product-colors d-flex">
                                    <span style={{background: "black"}} className="active"></span>
                                    <span style={{background: "maroon"}}></span>
                                    <span style={{background: "brown"}}></span>
                                </div>
                            </div>
                        </IonCol>

                        <IonCol size="6" className="product small-size ion-margin-bottom">
                            <div className="product-image">
                                <IonImg src={require("../assets/images/core-img/image-9.png").default}/>
                            </div>

                            <div className="product-description">
                                <h6 className="ion-text-uppercase">regular fit shirt with camp color</h6>
                                <h6 className="ion-text-uppercase">$ 45.5</h6>

                                <div className="product-colors d-flex">
                                    <span style={{background: "black"}} className="active"></span>
                                    <span style={{background: "maroon"}}></span>
                                    <span style={{background: "brown"}}></span>
                                </div>

                                <h6 className="ion-text-uppercase">join life</h6>
                            </div>
                        </IonCol>

                        <IonCol size="6" className="product small-size ion-margin-bottom">
                            <div className="product-image">
                                <IonImg src={require("../assets/images/core-img/image-10.png").default}/>
                            </div>

                            <div className="product-description">
                                <h6 className="ion-text-uppercase">regular fit shirt with camp color</h6>
                                <h6 className="ion-text-uppercase">$ 45.5</h6>

                                <div className="product-colors d-flex">
                                    <span style={{background: "black"}} className="active"></span>
                                    <span style={{background: "maroon"}}></span>
                                    <span style={{background: "brown"}}></span>
                                </div>

                                <h6 className="ion-text-uppercase">join life</h6>
                            </div>
                        </IonCol>

                        <IonCol size="6" className="product small-size ion-margin-bottom">
                            <div className="product-image">
                                <IonImg src={require("../assets/images/core-img/image-4.png").default}/>
                            </div>

                            <div className="product-description">
                                <h6 className="ion-text-uppercase">regular fit shirt with camp color</h6>
                                <h6 className="ion-text-uppercase">$ 45.5</h6>

                                <div className="product-colors d-flex">
                                    <span style={{background: "black"}} className="active"></span>
                                    <span style={{background: "maroon"}}></span>
                                    <span style={{background: "brown"}}></span>
                                </div>

                                <h6 className="ion-text-uppercase">join life</h6>
                            </div>
                        </IonCol>

                        <IonCol size="6" className="product small-size ion-margin-bottom">
                            <div className="product-image">
                                <IonImg src={require("../assets/images/core-img/image-5.png").default}/>
                            </div>

                            <div className="product-description">
                                <h6 className="ion-text-uppercase">regular fit shirt with camp color</h6>
                                <h6 className="ion-text-uppercase">$ 45.5</h6>

                                <div className="product-colors d-flex">
                                    <span style={{background: "black"}} className="active"></span>
                                    <span style={{background: "maroon"}}></span>
                                    <span style={{background: "brown"}}></span>
                                </div>

                                <h6 className="ion-text-uppercase">join life</h6>
                            </div>
                        </IonCol>

                        <IonCol size="6" className="product small-size ion-margin-bottom">
                            <div className="product-image">
                                <IonImg src={require("../assets/images/core-img/image-6.png").default}/>
                            </div>

                            <div className="product-description">
                                <h6 className="ion-text-uppercase">regular fit shirt with camp color</h6>
                                <h6 className="ion-text-uppercase">$ 45.5</h6>

                                <div className="product-colors d-flex">
                                    <span style={{background: "black"}} className="active"></span>
                                    <span style={{background: "maroon"}}></span>
                                    <span style={{background: "brown"}}></span>
                                </div>

                                <h6 className="ion-text-uppercase">join life</h6>
                            </div>
                        </IonCol>

                        <IonCol size="6" className="product small-size ion-margin-bottom">
                            <div className="product-image">
                                <IonImg src={require("../assets/images/core-img/image-7.png").default}/>
                            </div>

                            <div className="product-description">
                                <h6 className="ion-text-uppercase">regular fit shirt with camp color</h6>
                                <h6 className="ion-text-uppercase">$ 45.5</h6>

                                <div className="product-colors d-flex">
                                    <span style={{background: "black"}} className="active"></span>
                                    <span style={{background: "maroon"}}></span>
                                    <span style={{background: "brown"}}></span>
                                </div>

                                <h6 className="ion-text-uppercase">join life</h6>
                            </div>
                        </IonCol>

                    </IonRow>
                </IonContent>
            </IonPage>
        </>
    );
};

export default ProductSearch;
