import { Redirect, Route } from 'react-router-dom';
import {
  IonApp,
  IonIcon, IonImg,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import {homeOutline, menuOutline, personOutline, cartOutline, bagOutline, searchOutline} from 'ionicons/icons';

import Home from './pages/Home';
import Menu from './pages/Menu';
import Cart from './pages/Cart';
import AddBundle from './pages/AddBundle'
import ProductSubCategory from './pages/ProductSubCategory';
import MyAccount from "./pages/MyAccount";
import Login from "./pages/Login";
import Register from "./pages/Register";

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.scss';

/* Custom CSS */
import './theme/Tabs.scss';
import './theme/basics.scss'
import ProductDetail from "./pages/ProductDetail";
import ProductSearch from "./pages/ProductSearch";
import ShippingProcess from "./components/ShippingProcess";
import ShippingType from "./pages/ShippingType";
import ShippingAddress from "./pages/ShippingAddress";
import AddAddress from "./pages/AddAddress";
import PaymentMethod from "./pages/PaymentMethod";
import Shipping from "./pages/Shipping";



const App: React.FC = () => (
  <IonApp>
    <IonReactRouter>
      <IonTabs>
        <IonRouterOutlet>
          <Route exact path="/home">
            <Home />
          </Route>

          <Route exact path="/product-sub-category">
            <ProductSubCategory />
          </Route>

          <Route exact path="/menu">
            <Menu />
          </Route>

          <Route path="/cart">
            <Cart />
          </Route>

          <Route path="/add-bundle">
            <AddBundle />
          </Route>

          <Route path="/product-search">
            <ProductSearch />
          </Route>

          <Route path="/product-detail">
            <ProductDetail />
          </Route>

          <Route path="/shipping-type">
            <ShippingType />
          </Route>

          <Route path="/shipping-address">
            <ShippingAddress />
          </Route>

          <Route path="/shipping">
            <Shipping />
          </Route>

          <Route path="/add-address">
            <AddAddress />
          </Route>

          <Route path="/payment-method">
            <PaymentMethod />
          </Route>

          <Route path="/my-account">
            <MyAccount />
          </Route>

          <Route exact path="/login">
            <Login />
          </Route>

          <Route exact path="/register">
            <Register />
          </Route>

          <Route exact path="/">
            <Redirect to="/home" />
          </Route>
        </IonRouterOutlet>
        <IonTabBar slot="bottom">
          <IonTabButton tab="home" href="/home">
            <IonIcon icon={homeOutline} />
            {/*<IonImg src={require("./assets/images/icon/home.png").default}/>*/}
            <IonLabel>HOME</IonLabel>
          </IonTabButton>

          <IonTabButton tab="product-search" href="/product-search">
            <IonIcon icon={searchOutline} />
            <IonLabel>SEARCH</IonLabel>
          </IonTabButton>

          <IonTabButton tab="menu" href="/menu">
            <IonIcon icon={menuOutline} />
            <IonLabel>MENU</IonLabel>
          </IonTabButton>

          <IonTabButton tab="my-account" href="/my-account">
            <IonIcon icon={personOutline} />
            <IonLabel>MY ACCOUNT</IonLabel>
          </IonTabButton>

          <IonTabButton tab="cart" href="/cart">
            <IonIcon icon={bagOutline} />
            <IonLabel>CART</IonLabel>
          </IonTabButton>

        </IonTabBar>
      </IonTabs>
    </IonReactRouter>
  </IonApp>
);

export default App;
