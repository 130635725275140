import {
    IonAlert,
    IonBackButton, IonButton,
    IonButtons, IonCol,
    IonContent, IonGrid,
    IonHeader, IonIcon, IonImg, IonInput, IonItem,
    IonLabel, IonList,
    IonPage, IonRadio, IonRadioGroup, IonRow, IonSelect, IonSelectOption,
    IonTitle, IonToggle,
    IonToolbar
} from '@ionic/react';
import ShippingProcess from '../components/ShippingProcess';
import './ShippingType.scss';
import './Shipping.scss';

import React, {useState} from "react";
import {useHistory} from "react-router";
import {pencilSharp, trashOutline} from "ionicons/icons";

const Shipping: React.FC = () => {

    let history = useHistory();
    const [text, setText] = useState<string>();
    const [checked, setChecked] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const alertImage = require("../assets/images/icon/check-mark.png").default;

    return (
        <>
            <IonPage>
                <IonHeader className="ion-no-border main-header">
                    <IonToolbar>
                        <IonButtons slot="start">
                            <IonBackButton defaultHref="home"/>
                        </IonButtons>
                        <IonTitle>SHIPPING</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonContent className="ion-padding shipping-container">
                    <ShippingProcess/>

                    <IonGrid className="cart-container">
                        <h4 className="process-name ion-text-uppercase">Cart Items</h4>

                        <IonRow className="cart-row">
                            <IonCol size="3" className="product-img">
                                <IonImg src={require("../assets/images/core-img/image-4.png").default}/>
                            </IonCol>

                            <IonCol size="9" className="product-detail d-flex ion-justify-content-between">
                                <div className="top-row">
                                    <h5 className="ion-text-uppercase">long 100% linen halter neck dress</h5>
                                    <h6 className="ion-text-uppercase">size <span>s</span></h6>
                                    <h6 className="ion-text-uppercase">Lime</h6>
                                </div>

                                <div className="bottom-row d-flex ion-justify-content-between ion-align-items-center">
                                    <h6 className="ion-text-uppercase">Quantity: <span>1</span></h6>
                                    <span className="product-price">$ 20.9</span>
                                </div>
                            </IonCol>
                        </IonRow>
                        <IonRow className="cart-row">
                            <IonCol size="3" className="product-img">
                                <IonImg src={require("../assets/images/core-img/image-5.png").default}/>
                            </IonCol>

                            <IonCol size="9" className="product-detail d-flex ion-justify-content-between">
                                <div className="top-row">
                                    <h5 className="ion-text-uppercase">long 100% linen halter neck dress</h5>
                                    <h6 className="ion-text-uppercase">size <span>s</span></h6>
                                    <h6 className="ion-text-uppercase">Lime</h6>
                                </div>

                                <div className="bottom-row d-flex ion-justify-content-between ion-align-items-center">
                                    <h6 className="ion-text-uppercase">Quantity: <span>1</span></h6>
                                    <span className="product-price">$ 20.9</span>
                                </div>
                            </IonCol>
                        </IonRow>
                        <IonRow className="cart-row">
                            <IonCol size="3" className="product-img">
                                <IonImg src={require("../assets/images/core-img/image-6.png").default}/>
                            </IonCol>

                            <IonCol size="9" className="product-detail d-flex ion-justify-content-between">
                                <div className="top-row">
                                    <h5 className="ion-text-uppercase">long 100% linen halter neck dress</h5>
                                    <h6 className="ion-text-uppercase">size <span>s</span></h6>
                                    <h6 className="ion-text-uppercase">Lime</h6>
                                </div>

                                <div className="bottom-row d-flex ion-justify-content-between ion-align-items-center">
                                    <h6 className="ion-text-uppercase">Quantity: <span>1</span></h6>
                                    <span className="product-price">$ 20.9</span>
                                </div>
                            </IonCol>
                        </IonRow>
                        <IonRow className="cart-row">
                            <IonCol size="3" className="product-img">
                                <IonImg src={require("../assets/images/core-img/image-7.png").default}/>
                            </IonCol>

                            <IonCol size="9" className="product-detail d-flex ion-justify-content-between">
                                <div className="top-row">
                                    <h5 className="ion-text-uppercase">long 100% linen halter neck dress</h5>
                                    <h6 className="ion-text-uppercase">size <span>s</span></h6>
                                    <h6 className="ion-text-uppercase">Lime</h6>
                                </div>

                                <div className="bottom-row d-flex ion-justify-content-between ion-align-items-center">
                                    <h6 className="ion-text-uppercase">Quantity: <span>1</span></h6>
                                    <span className="product-price">$ 20.9</span>
                                </div>
                            </IonCol>
                        </IonRow>
                    </IonGrid>


                    <div className="shipping-type-container">
                        <h4 className="process-name ion-text-uppercase">Shipping Type</h4>

                        <IonList className="shipping-list">
                            <IonItem button onClick={() => {
                                history.push('/shipping-type');
                            }} detail>
                                <IonLabel>
                                    <h2>Express 19.9 $</h2>

                                    <p>5 - 7 Business days</p>
                                </IonLabel>
                            </IonItem>
                        </IonList>
                    </div>

                    <div className="shipping-address-container">
                        <h4 className="process-name ion-text-uppercase">Shipping Address</h4>

                        <IonList className="shipping-list">
                            <IonItem detail onClick={() => {
                                history.push('/shipping-address');
                            }}>
                                <IonLabel>
                                    <h2>Junaid Chulbul Pandey</h2>
                                    <p>2958 Oxford Court, Moorhead, Mississippi</p>
                                </IonLabel>
                            </IonItem>
                        </IonList>
                    </div>

                    <div className="payment-method-container">
                        <h4 className="process-name ion-text-uppercase">Payment Method</h4>

                        <div className="payment-methods-list">
                            <div className="payment-method d-flex ion-align-items-center" onClick={() => {
                                history.push('/payment-method');
                            }}>
                                <IonImg src={require("../assets/images/icon/Visa.png").default}/>
                                <IonLabel>
                                    <h3>VISA</h3>
                                    <p>**** **** **** 1111</p>
                                </IonLabel>
                            </div>

                            <div className="promo-code d-flex">
                                <IonInput className="saint-input" value={text} placeholder="Enter Promotion Code"/>
                                <IonButton className="saint-btn">Add to basket</IonButton>
                            </div>
                        </div>
                    </div>

                    <div className="order-detail-container">
                        <h4 className="process-name ion-text-uppercase">Order Detail</h4>

                        <div className="order-detail">
                            <div className="detail-item d-flex ion-justify-content-between">
                                <span>10 items</span>
                                <span>$ 600.95</span>
                            </div>

                            <div className="detail-item d-flex ion-justify-content-between">
                                <span>Shipping</span>
                                <span>$ 0.00</span>
                            </div>

                            <div className="detail-item d-flex ion-justify-content-between">
                                <span>Discount</span>
                                <span>$ 0.00</span>
                            </div>


                            <div className="detail-item d-flex ion-justify-content-between">
                                <span>Import costs</span>
                                <span>$ 31.00</span>
                            </div>

                            <div className="order-total d-flex ion-justify-content-between">
                                <span>Total</span>
                                <span>$ 631.95</span>
                            </div>
                        </div>
                    </div>

                    <div className="privacy-policy">
                        <IonList lines="none">
                            <IonItem>
                                <IonLabel className="ion-text-wrap">I accept the privacy policy and cancellation
                                    policies</IonLabel>
                                <IonToggle slot="end" checked={checked}
                                           onIonChange={e => setChecked(e.detail.checked)}/>
                            </IonItem>

                            <div className="privacy-policy-links">
                                <a href="" className="">Privacy policy</a>
                                <a href="">Purchase conditions</a>
                            </div>
                        </IonList>
                    </div>
                </IonContent>

                <div className="cart-total ion-padding">
                    <div className="total-price ion-justify-content-between">
                        <span>TOTAL</span>
                        <span>$ 631.95</span>
                    </div>

                    <IonButton expand="block" color="primary" className="saint-btn" onClick={() => {
                        setShowAlert(true)
                    }}>Authorize Payment</IonButton>
                </div>

                <IonAlert
                    isOpen={showAlert}
                    onDidDismiss={() => setShowAlert(false)}
                    cssClass='order-alert'
                    message={`<img src = "${alertImage}"/> <p>your order has been placed successfully!</p>`}
                />
            </IonPage>
        </>
    );
};

export default Shipping;
