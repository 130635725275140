import {
    IonBackButton, IonBadge, IonButton,
    IonButtons, IonCheckbox, IonCol,
    IonContent,
    IonGrid,
    IonHeader, IonIcon, IonImg, IonItem, IonItemDivider,
    IonItemSliding, IonLabel, IonList, IonMenu, IonMenuButton,
    IonPage, IonRouterOutlet, IonRow, IonSelect, IonSelectOption, IonSlide, IonSlides, IonText,
    IonTitle,
    IonToolbar
} from '@ionic/react';

import {menuController} from '@ionic/core';

import './ProductSubCategory.scss';
import React, {useState} from "react";
import {
    addOutline, bagOutline,
    cartOutline,
    closeOutline,
    searchOutline,
    trash,
    trashBinOutline,
    trashOutline
} from "ionicons/icons";
import {Link, useHistory} from "react-router-dom";

const openFirst = () => {
    menuController.enable(true, 'first');
    menuController.open('first');
};

const closeFirst = () => {
    menuController.close('first');
};


const ProductSubCategory: React.FC = () => {

    let history = useHistory();

    const slideOpts = {
        slidesPerView: 2.2,
        speed: 400,
    };

    return (
        <>
            <IonPage>
                <IonHeader className="ion-no-border sub-category-header main-header">
                    <IonToolbar>
                        <IonButtons slot="start">
                            <IonBackButton defaultHref=""/>
                        </IonButtons>
                        <IonTitle className="ion-text-uppercase">Linen Collection</IonTitle>

                        <IonButtons slot="end" onClick={()=>{
                            history.push('/cart')
                        }}>
                            <IonIcon icon={bagOutline} color="primary"/>
                            <IonBadge>3</IonBadge>
                        </IonButtons>
                    </IonToolbar>

                    <div className="filter-bar d-flex ion-justify-content-between">
                        <IonButton className="btn-link">See all</IonButton>
                        <IonButton className="btn-link" onClick={() => openFirst()}>Filters</IonButton>
                    </div>
                </IonHeader>

                <IonContent fullscreen>
                    <div className="sub-category-container">
                        <IonRow>
                            <IonCol size="12" className="product large-size ion-margin-bottom">
                                <div className="product-image">
                                    <IonImg src={require("../assets/images/core-img/image-9.png").default}/>
                                </div>

                                <div className="product-description">
                                    <h6 className="ion-text-uppercase">regular fit shirt with camp color</h6>
                                    <h6 className="ion-text-uppercase">$ 45.5</h6>

                                    <div className="product-colors d-flex">
                                        <span style={{background: "black"}} className="active"></span>
                                        <span style={{background: "yellow"}}></span>
                                        <span style={{background: "maroon"}}></span>
                                        <span style={{background: "brown"}}></span>
                                    </div>
                                </div>
                            </IonCol>

                            <IonCol size="12" className="product large-size ion-margin-bottom">
                                <div className="product-image">
                                    <IonImg src={require("../assets/images/core-img/image-8.png").default}/>
                                </div>

                                <div className="product-description">
                                    <h6 className="ion-text-uppercase">regular fit shirt with camp color</h6>
                                    <span>4 items</span>
                                </div>
                            </IonCol>
                        </IonRow>

                        <IonRow>
                            <IonCol size="6" className="product small-size ion-margin-bottom" onClick={()=>{
                                history.push('product-detail')
                            }}>
                                <div className="product-image">
                                    <IonImg src={require("../assets/images/core-img/image-3.png").default}/>
                                </div>

                                <div className="product-description">
                                    <h6 className="ion-text-uppercase">regular fit shirt with camp color</h6>
                                    <h6 className="ion-text-uppercase">$ 45.5</h6>
                                    <div className="product-colors d-flex">
                                        <span style={{background: "black"}} className="active"></span>
                                        <span style={{background: "maroon"}}></span>
                                        <span style={{background: "brown"}}></span>
                                    </div>
                                </div>
                            </IonCol>

                            <IonCol size="6" className="product small-size ion-margin-bottom">
                                <div className="product-image">
                                    <IonImg src={require("../assets/images/core-img/image-2.png").default}/>
                                </div>

                                <div className="product-description">
                                    <h6 className="ion-text-uppercase">regular fit shirt with camp color</h6>
                                    <h6 className="ion-text-uppercase">$ 45.5</h6>
                                    <div className="product-colors d-flex">
                                        <span style={{background: "black"}} className="active"></span>
                                        <span style={{background: "maroon"}}></span>
                                        <span style={{background: "brown"}}></span>
                                    </div>
                                </div>
                            </IonCol>

                            <IonCol size="6" className="product small-size ion-margin-bottom">
                                <div className="product-image">
                                    <IonImg src={require("../assets/images/core-img/image-4.png").default}/>
                                </div>

                                <div className="product-description">
                                    <h6 className="ion-text-uppercase">regular fit shirt with camp color</h6>
                                    <h6 className="ion-text-uppercase">$ 45.5</h6>

                                    <div className="product-colors d-flex">
                                        <span style={{background: "black"}} className="active"></span>
                                        <span style={{background: "maroon"}}></span>
                                        <span style={{background: "brown"}}></span>
                                    </div>

                                    <h6 className="ion-text-uppercase">join life</h6>
                                </div>
                            </IonCol>

                            <IonCol size="6" className="product small-size ion-margin-bottom">
                                <div className="product-image">
                                    <IonImg src={require("../assets/images/core-img/image-5.png").default}/>
                                </div>

                                <div className="product-description">
                                    <h6 className="ion-text-uppercase">regular fit shirt with camp color</h6>
                                    <h6 className="ion-text-uppercase">$ 45.5</h6>
                                    <div className="product-colors d-flex">
                                        <span style={{background: "black"}} className="active"></span>
                                        <span style={{background: "maroon"}}></span>
                                        <span style={{background: "brown"}}></span>
                                    </div>

                                    <h6 className="ion-text-uppercase">join life</h6>
                                </div>
                            </IonCol>
                        </IonRow>

                        <IonRow>
                            <IonSlides options={slideOpts}>
                                <IonSlide className="product">
                                    <div className="product-image">
                                        <IonImg src={require("../assets/images/core-img/image-8.png").default}/>
                                    </div>

                                    <div className="product-description">
                                        <h6 className="ion-text-uppercase">regular fit shirt with camp color</h6>
                                        <h6 className="ion-text-uppercase">$ 45.5</h6>

                                        <div className="product-colors d-flex">
                                            <span style={{background: "black"}} className="active"></span>
                                            <span style={{background: "maroon"}}></span>
                                            <span style={{background: "brown"}}></span>
                                        </div>
                                    </div>
                                </IonSlide>

                                <IonSlide className="product">
                                    <div className="product-image">
                                        <IonImg src={require("../assets/images/core-img/image-6.png").default}/>
                                    </div>

                                    <div className="product-description">
                                        <h6 className="ion-text-uppercase">regular fit shirt with camp color</h6>
                                        <h6 className="ion-text-uppercase">$ 45.5</h6>
                                        <div className="product-colors d-flex">
                                            <span style={{background: "black"}} className="active"></span>
                                            <span style={{background: "maroon"}}></span>
                                            <span style={{background: "brown"}}></span>
                                        </div>
                                    </div>
                                </IonSlide>

                                <IonSlide className="product">
                                    <div className="product-image">
                                        <IonImg src={require("../assets/images/core-img/image-7.png").default}/>
                                    </div>

                                    <div className="product-description">
                                        <h6 className="ion-text-uppercase">regular fit shirt with camp color</h6>
                                        <h6 className="ion-text-uppercase">$ 45.5</h6>
                                        <div className="product-colors d-flex">
                                            <span style={{background: "black"}} className="active"></span>
                                            <span style={{background: "maroon"}}></span>
                                            <span style={{background: "brown"}}></span>
                                        </div>
                                    </div>
                                </IonSlide>

                                <IonSlide className="product">
                                    <div className="product-image">
                                        <IonImg src={require("../assets/images/core-img/image-8.png").default}/>
                                    </div>

                                    <div className="product-description">
                                        <h6 className="ion-text-uppercase">regular fit shirt with camp color</h6>
                                        <h6 className="ion-text-uppercase">$ 45.5</h6>
                                        <div className="product-colors d-flex">
                                            <span style={{background: "black"}} className="active"></span>
                                            <span style={{background: "maroon"}}></span>
                                            <span style={{background: "brown"}}></span>
                                        </div>
                                    </div>
                                </IonSlide>

                                <IonSlide className="product">
                                    <div className="product-image">
                                        <IonImg src={require("../assets/images/core-img/image-9.png").default}/>
                                    </div>

                                    <div className="product-description">
                                        <h6 className="ion-text-uppercase">regular fit shirt with camp color</h6>
                                        <h6 className="ion-text-uppercase">$ 45.5</h6>
                                        <div className="product-colors d-flex">
                                            <span style={{background: "black"}} className="active"></span>
                                            <span style={{background: "maroon"}}></span>
                                            <span style={{background: "brown"}}></span>
                                        </div>
                                    </div>
                                </IonSlide>

                                <IonSlide className="product">
                                    <div className="product-image">
                                        <IonImg src={require("../assets/images/core-img/image-10.png").default}/>
                                    </div>

                                    <div className="product-description">
                                        <h6 className="ion-text-uppercase">regular fit shirt with camp color</h6>
                                        <h6 className="ion-text-uppercase">$ 45.5</h6>
                                        <div className="product-colors d-flex">
                                            <span style={{background: "black"}} className="active"></span>
                                            <span style={{background: "maroon"}}></span>
                                            <span style={{background: "brown"}}></span>
                                        </div>
                                    </div>
                                </IonSlide>
                            </IonSlides>
                        </IonRow>
                    </div>
                </IonContent>
            </IonPage>
            <IonMenu side="end" menuId="first" contentId="main">
                <IonHeader>
                    <IonToolbar>
                        <IonTitle>Delete Filters</IonTitle>
                        <IonIcon slot="end" icon={closeOutline} onClick={() => closeFirst()}/>
                    </IonToolbar>
                </IonHeader>

                <IonContent>
                    <IonList lines="full" className="filter-list">
                        <IonItemDivider><IonLabel color="primary">Filter By</IonLabel></IonItemDivider>

                        <div className="filter-name d-flex ion-justify-content-between ion-align-items-center">
                            <IonLabel>Size</IonLabel>
                            <IonIcon slot="end" icon={addOutline}/>
                        </div>

                        <IonItem>
                            <IonList lines="none">
                                <IonItem>
                                    <IonLabel>
                                        <h4>36</h4>
                                    </IonLabel>
                                    <IonCheckbox slot="start"/>
                                </IonItem>

                                <IonItem>
                                    <IonLabel>
                                        <h4>37</h4>
                                    </IonLabel>
                                    <IonCheckbox slot="start"/>
                                </IonItem>

                                <IonItem>
                                    <IonLabel>
                                        <h4>38</h4>
                                    </IonLabel>
                                    <IonCheckbox slot="start"/>
                                </IonItem>
                            </IonList>
                        </IonItem>

                        <div className="filter-name d-flex ion-justify-content-between ion-align-items-center">
                            <IonLabel>Color</IonLabel>
                            <IonIcon slot="end" icon={addOutline}/>
                        </div>

                        <IonItem>
                            <IonList lines="none">
                                <IonItem>
                                    <IonLabel>
                                        <span className="product-color" style={{background: "brown"}}></span>
                                    </IonLabel>
                                    <IonCheckbox slot="start"/>
                                </IonItem>

                                <IonItem>
                                    <IonLabel>
                                        <span className="product-color" style={{background: "black"}}></span>
                                    </IonLabel>
                                    <IonCheckbox slot="start"/>
                                </IonItem>

                                <IonItem>
                                    <IonLabel>
                                        <span className="product-color" style={{background: "white"}}></span>
                                    </IonLabel>
                                    <IonCheckbox slot="start"/>
                                </IonItem>
                            </IonList>
                        </IonItem>

                        <div className="filter-name d-flex ion-justify-content-between ion-align-items-center">
                            <IonLabel>Quality</IonLabel>
                            <IonIcon slot="end" icon={addOutline}/>
                        </div>

                        <IonItem>
                            <IonList lines="none">
                                <IonItem>
                                    <IonLabel>
                                        <h4>Linen</h4>
                                    </IonLabel>
                                    <IonCheckbox slot="start"/>
                                </IonItem>

                                <IonItem>
                                    <IonLabel>
                                        <h4>Cotton</h4>
                                    </IonLabel>
                                    <IonCheckbox slot="start"/>
                                </IonItem>

                                <IonItem>
                                    <IonLabel>
                                        <h4>Silk</h4>
                                    </IonLabel>
                                    <IonCheckbox slot="start"/>
                                </IonItem>

                                <IonItem>
                                    <IonLabel>
                                        <h4>wool</h4>
                                    </IonLabel>
                                    <IonCheckbox slot="start"/>
                                </IonItem>
                            </IonList>
                        </IonItem>
                    </IonList>

                    <div className="filter-btn">
                        <IonButton expand="full" className="saint-btn">Apply</IonButton>
                    </div>
                </IonContent>
            </IonMenu>
            <IonRouterOutlet id="main"></IonRouterOutlet>
        </>
    );
};

export default ProductSubCategory;
