import {
    IonButtons,
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonBackButton,
    IonIcon,
    IonButton, IonInput, IonLabel, IonItem, IonToggle, IonList, IonRadio, IonRadioGroup, IonSegment, IonSegmentButton
} from '@ionic/react';
import {personOutline} from "ionicons/icons";
import './Login.scss';
import './Register.scss';
import React, {useState} from "react";
import {Link} from "react-router-dom";

const Register: React.FC = () => {

    const [text, setText] = useState<string>();

    const [checked1, setChecked1] = useState(false);
    const [checked2, setChecked2] = useState(false);

    return (
        <IonPage>
            <IonHeader className="ion-no-border main-header">
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="home"/>
                    </IonButtons>
                    <IonTitle>Create an account</IonTitle>
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen className="my-account-container ion-padding">
                <div className="existing-customer">
                    <div className="register-form">

                        <div className="gender-selection ion-align-items-center">
                            <IonIcon icon={personOutline}/>

                            <IonSegment onIonChange={e => e.detail.value}>
                                <IonSegmentButton value="friends">
                                    <IonLabel>Women</IonLabel>
                                </IonSegmentButton>
                                <IonSegmentButton value="enemies">
                                    <IonLabel>Men</IonLabel>
                                </IonSegmentButton>
                            </IonSegment>
                        </div>

                        <IonInput className="saint-input" value={text} placeholder="First Name*"/>
                        <IonInput className="saint-input" value={text} placeholder="Last Name*"/>
                        <IonInput className="saint-input" value={text} placeholder="Email*"/>
                        <IonInput className="saint-input" value={text} placeholder="Password*"/>

                        <IonList lines="none" className="ion-margin-bottom">
                            <IonItem>
                                <IonLabel>I accept the privacy policy</IonLabel>
                                <IonToggle slot="end" checked={checked1}
                                           onIonChange={e => setChecked1(e.detail.checked)}/>
                            </IonItem>

                            <IonItem>
                                <IonLabel>Receive Newsletter</IonLabel>
                                <IonToggle slot="end" checked={checked2}
                                           onIonChange={e => setChecked2(e.detail.checked)}/>
                            </IonItem>
                        </IonList>

                        <IonButton expand="block" class="saint-btn">Create an account</IonButton>
                    </div>
                </div>
            </IonContent>
        </IonPage>
    );
};

export default Register;
