import {
    IonButton,
    IonContent,
    IonHeader,
    IonIcon,
    IonImg,
    IonLabel,
    IonPage,
    IonSegment,
    IonSegmentButton,
    IonTitle,
    IonToolbar,
    IonSlide,
    IonRow,
    IonMenuButton,
    IonSearchbar,
    IonButtons,
    IonSlides,
    IonGrid,
    IonCol,
    IonCard,
    IonCardHeader,
    IonCardSubtitle,
    IonCardTitle, IonCardContent, IonBadge
} from '@ionic/react';
import ExploreContainer from '../components/ExploreContainer';
import './Menu.scss';
import {bagOutline, bookmark, camera, cart, cartOutline, search, searchOutline} from "ionicons/icons";

// added useRef
import React, {useState, useRef} from 'react';

const Menu: React.FC = () => {

    const [searchText, setSearchText] = useState('');

    // a ref variable to handle the current slider
    const slider = useRef<HTMLIonSlidesElement>(null);
    // a state value to bind segment value
    const [value, setValue] = useState("0");

    const slideOpts = {
        initialSlide: 1,
        speed: 400,
        loop: false,
        pagination: {
            el: null
        },

    };

    // a function to handle the segment changes
    const handleSegmentChange = (e: any) => {
        setValue(e.detail.value);
        slider.current!.slideTo(e.detail.value);
    };

    // a function to handle the slider changes
    const handleSlideChange = async (event: any) => {
        let index: number = 0;
        await event.target.getActiveIndex().then((value: any) => (index = value));
        setValue('' + index)
    };


    return (
        <IonPage>
            <IonHeader className="ion-no-border main-header">
                <IonToolbar>
                    <IonImg slot="start" src={require("../assets/images/icon/icon.png").default}/>
                    <IonIcon slot="end" icon={searchOutline} color="primary"/>
                    <IonButtons slot="end">
                        <IonIcon icon={bagOutline} color="primary"/>
                        <IonBadge>3</IonBadge>
                    </IonButtons>
                </IonToolbar>


                <IonSegment value={value} onIonChange={(e) => handleSegmentChange(e)}>
                    <IonSegmentButton value="0">
                        <IonLabel>Women</IonLabel>
                    </IonSegmentButton>
                    <IonSegmentButton value="1">
                        <IonLabel>Men</IonLabel>
                    </IonSegmentButton>
                </IonSegment>

            </IonHeader>

            <IonContent>

                <IonSlides pager={true} options={slideOpts} onIonSlideDidChange={(e) => handleSlideChange(e)}
                           ref={slider}>
                    <IonSlide className="menu-wrapper">
                        <div className="menu-category">
                            <ExploreContainer name="MENU MEN"/>
                        </div>
                    </IonSlide>

                    <IonSlide className="menu-wrapper">
                        <div className="menu-category">
                            <ExploreContainer name="MENU WOMEN"/>
                        </div>
                    </IonSlide>
                </IonSlides>
            </IonContent>
        </IonPage>
    )
};

export default Menu;

