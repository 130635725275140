import './ShippingProcess.scss';
import {IonIcon, IonImg} from "@ionic/react";
import React, {useEffect} from "react";

const ShippingProcess: React.FC = (props) => {
    let pathname = window.location.pathname;
    useEffect(() => {
        pathname = window.location.pathname;

    }, [window.location.pathname]);



  return (
    <div className="process-list">
       <ul className="d-flex">
           <li className={`${pathname == '/shipping-type' ? 'active' : ''}`}>
               <IonImg src={require("../assets/images/icon/open-box.png").default}/>
           </li>

           <li className={`${pathname == '/shipping-address' || pathname == '/add-address' ? 'active' : ''}`}>
               <IonImg src={require("../assets/images/icon/address.png").default}/>
           </li>

           <li className={`${pathname == '/payment-method' ? 'active' : ''}`}>
               <IonImg src={require("../assets/images/icon/credit-card.png").default}/>
           </li>

           <li className={`${pathname == '/shipping' ? 'active' : ''}`}>
               <IonImg src={require("../assets/images/icon/shipping.png").default}/>
           </li>
       </ul>
    </div>
  );
};

export default ShippingProcess;
