import {
    IonButtons,
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonBackButton,
    IonIcon,
    IonButton, IonInput
} from '@ionic/react';
import {personOutline} from "ionicons/icons";
import './Login.scss';
import React, {useState} from "react";
import {Link} from "react-router-dom";

const Login: React.FC = () => {

    const [text, setText] = useState<string>();

    return (
        <IonPage>
            <IonHeader className="ion-no-border main-header">
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="home"/>
                    </IonButtons>
                    <IonTitle>LOG IN</IonTitle>
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen className="my-account-container ion-padding">
                <div className="existing-customer">
                    <div className="customer-type ion-text-center">
                        <IonIcon icon={personOutline}/>
                        <h4>Existing Customer</h4>
                    </div>

                    <div className="login-form">
                        <IonInput className="saint-input" value={text} placeholder="Email*"/>
                        <IonInput className="saint-input" value={text} placeholder="Password*"/>
                        <IonButton expand="block" class="saint-btn">Log in</IonButton>
                        <Link to="/reset-password">Forget Password?</Link>
                    </div>
                </div>
            </IonContent>
        </IonPage>
    );
};

export default Login;
