import {
    IonBackButton, IonButton,
    IonButtons,
    IonContent,
    IonHeader, IonIcon, IonInput, IonItem,
    IonLabel, IonList, IonListHeader,
    IonPage, IonRadio, IonRadioGroup, IonSegment, IonSegmentButton, IonSelect, IonSelectOption,
    IonTitle, IonToggle,
    IonToolbar
} from '@ionic/react';
import ShippingProcess from '../components/ShippingProcess';
import './AddAddress.scss';
import React, {useState} from "react";
import {pencil, pencilOutline, pencilSharp, personOutline} from "ionicons/icons";
import {useHistory} from "react-router";

const AddAddress: React.FC = () => {
    let history = useHistory();

    const [text, setText] = useState<string>();

    // const [checked1, setChecked1] = useState(false);
    const [countryName, setCountryName] = useState<string>('Pakistan');


    return (
        <>
            <IonPage>
                <IonHeader className="ion-no-border main-header">
                    <IonToolbar>
                        <IonButtons slot="start">
                            <IonBackButton defaultHref="home"/>
                        </IonButtons>

                        <IonTitle>Add Address</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonContent className="ion-padding">
                    <ShippingProcess/>

                    <div className="register-form">

                        <div className="gender-selection ion-align-items-center">
                            <IonIcon icon={personOutline}/>

                            <IonSegment onIonChange={e => e.detail.value}>
                                <IonSegmentButton value="friends">
                                    <IonLabel>Women</IonLabel>
                                </IonSegmentButton>

                                <IonSegmentButton value="enemies">
                                    <IonLabel>Men</IonLabel>
                                </IonSegmentButton>
                            </IonSegment>
                        </div>

                        <IonInput className="saint-input" type="text" value={text} placeholder="First Name*"
                                  clearInput/>

                        <IonInput className="saint-input" type="text" value={text} placeholder="Last Name*" clearInput/>

                        <IonInput className="saint-input" type="text" value={text} placeholder="Address*" clearInput/>

                        <IonInput className="saint-input" type="text" value={text} placeholder="Province" clearInput/>

                        <IonInput className="saint-input" type="text" value={text} placeholder="City" clearInput/>

                        <IonInput className="saint-input" type="number" value={text} placeholder="Post Code*"
                                  clearInput/>

                        <div className="phone-input d-flex ion-align-items-center">
                            <IonSelect slot="start" value={countryName} okText="Okay" cancelText="Cancel"
                                       onIonChange={e => setCountryName(e.detail.value)}>
                                <IonSelectOption value="Pakistan">Pakistan</IonSelectOption>
                                <IonSelectOption value="Spain">Spain</IonSelectOption>
                                <IonSelectOption value="Australia">Australia</IonSelectOption>
                                <IonSelectOption value="United States">United States</IonSelectOption>
                            </IonSelect>
                            <IonInput className="saint-input" type="text" value="+92 " placeholder="*" clearInput/>
                        </div>

                        <IonButton expand="block" class="saint-btn" onClick={() => {
                            history.push('/shipping-address');
                        }}>Save</IonButton>
                    </div>

                </IonContent>
            </IonPage>
        </>
    );
};

export default AddAddress;
