import {
    IonBackButton,
    IonButtons,
    IonContent,
    IonHeader, IonImg, IonItem,
    IonLabel, IonList,
    IonPage,
    IonTitle,
    IonToolbar
} from '@ionic/react';
import ShippingProcess from '../components/ShippingProcess';
import './PaymentMethod.scss';

import React, {useState} from "react";
import {useHistory} from "react-router";

const PaymentMethod: React.FC = () => {

    let history = useHistory();
    return (
        <>
            <IonPage>
                <IonHeader className="ion-no-border main-header">
                    <IonToolbar>
                        <IonButtons slot="start">
                            <IonBackButton defaultHref="home"/>
                        </IonButtons>
                        <IonTitle>PAYMENT</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonContent className="ion-padding payment-method-container">
                    <ShippingProcess/>

                    <h4 className="process-name ion-text-uppercase">Payment Method</h4>

                    <div className="payment-methods-list">
                        <div className="payment-method d-flex ion-align-items-center" onClick={() => {
                            history.push('/shipping');
                        }}>
                            <IonImg src={require("../assets/images/icon/Visa.png").default}/>
                            <IonLabel>
                                <h3>VISA</h3>
                                <p>**** **** **** 1111</p>
                            </IonLabel>
                        </div>

                        <div className="payment-method d-flex ion-align-items-center">
                            <IonImg src={require("../assets/images/icon/Mastercard.png").default}/>
                            <IonLabel>
                                <h3>Master Card</h3>
                            </IonLabel>
                        </div>

                        <div className="payment-method d-flex ion-align-items-center">
                            <IonImg src={require("../assets/images/icon/Amazon Payments.png").default}/>
                            <IonLabel>Amazon Pay</IonLabel>
                        </div>

                        <div className="payment-method d-flex ion-align-items-center">
                            <IonImg src={require("../assets/images/icon/PayPal.png").default}/>
                            <IonLabel>PayPal</IonLabel>
                        </div>
                    </div>
                </IonContent>
            </IonPage>
        </>
    );
};

export default PaymentMethod;
