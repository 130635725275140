import {
    IonBackButton, IonBadge, IonButton,
    IonButtons, IonCheckbox, IonCol,
    IonContent,
    IonGrid,
    IonHeader, IonIcon, IonImg, IonItem, IonItemOptions,
    IonItemSliding, IonLabel, IonList,
    IonPage, IonRow, IonSelect, IonSelectOption, IonSlide, IonSlides,
    IonTitle,
    IonToolbar
} from '@ionic/react';
import ExploreContainer from '../components/ExploreContainer';
import './ProductDetail.scss';
import React, {useState} from "react";
import {bagOutline, cartOutline, trash, trashBinOutline, trashOutline} from "ionicons/icons";
import {useHistory} from "react-router";

const ProductDetail: React.FC = () => {

    let history = useHistory();

    const slideOpts = {
        initialSlide: 1,
        speed: 400,
        direction:"vertical"
    };

    const mainSlideOpts = {
        initialSlide: 1,
        speed: 400,
    };

    return (
        <IonPage>
            <IonHeader className="ion-no-border main-header">
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref=""/>
                    </IonButtons>

                    <IonTitle>Product Detail</IonTitle>

                    <IonButtons slot="end" onClick={()=>{
                        history.push('/cart')
                    }}>
                        <IonIcon icon={bagOutline} color="primary"/>
                        <IonBadge>3</IonBadge>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>

            <IonContent className="product-detail-container">

                <IonSlides options={mainSlideOpts}>
                    <IonSlide>
                        <IonSlides pager={true} options={slideOpts}>
                            <IonSlide>
                                <IonImg src={require("../assets/images/core-img/image-6.png").default}/>
                            </IonSlide>
                            <IonSlide>
                                <IonImg src={require("../assets/images/core-img/image-7.png").default}/>
                            </IonSlide>
                            <IonSlide>
                                <IonImg src={require("../assets/images/core-img/image-8.png").default}/>
                            </IonSlide>
                        </IonSlides>
                    </IonSlide>
                    <IonSlide>
                        <IonSlides pager={true} options={slideOpts}>
                            <IonSlide>
                                <IonImg src={require("../assets/images/core-img/image-4.png").default}/>
                            </IonSlide>

                            <IonSlide>
                                <IonImg src={require("../assets/images/core-img/image-5.png").default}/>
                            </IonSlide>

                            <IonSlide>
                                <IonImg src={require("../assets/images/core-img/image-9.png").default}/>
                            </IonSlide>

                            <IonSlide>
                                <IonImg src={require("../assets/images/core-img/image-10.png").default}/>
                            </IonSlide>
                        </IonSlides>
                    </IonSlide>

                    <IonSlide>
                        <IonSlides pager={true} options={slideOpts}>
                            <IonSlide>
                                <IonImg src={require("../assets/images/core-img/image-6.png").default}/>
                            </IonSlide>

                            <IonSlide>
                                <IonImg src={require("../assets/images/core-img/image-7.png").default}/>
                            </IonSlide>

                            <IonSlide>
                                <IonImg src={require("../assets/images/core-img/image-8.png").default}/>
                            </IonSlide>
                        </IonSlides>
                    </IonSlide>
                </IonSlides>

                <div className="product-colors">
                    <div className="d-flex ion-justify-content-center">
                        <div className="product-color active">
                            <span style={{background: "black"}}></span>
                        </div>

                        <div className="product-color">
                            <span style={{background: "maroon"}}></span>
                        </div>

                        <div className="product-color">
                            <span style={{background: "purple"}}></span>
                        </div>
                    </div>
                </div>

                <div className="cart-total ion-padding">
                    <div className="total-price ion-justify-content-between">
                        <span>TOTAL</span>
                        <span>$ 100.5</span>
                    </div>

                    <IonButton expand="block" color="primary" className="saint-btn" onClick={()=>{
                        history.push('/cart')
                    }}>Add to basket</IonButton>
                </div>
            </IonContent>
        </IonPage>
    );
};

export default ProductDetail;
