import {
    IonBackButton,
    IonButtons,
    IonContent,
    IonHeader, IonItem,
    IonLabel, IonList,
    IonPage,
    IonTitle,
    IonToolbar
} from '@ionic/react';
import ShippingProcess from '../components/ShippingProcess';
import './ShippingType.scss';

import React, {useState} from "react";
import {useHistory} from "react-router";

const ShippingType: React.FC = () => {

    let history = useHistory();
    return (
        <>
            <IonPage>
                <IonHeader className="ion-no-border main-header">
                    <IonToolbar>
                        <IonButtons slot="start">
                            <IonBackButton defaultHref="home"/>
                        </IonButtons>
                        <IonTitle>SHIPPING TYPE</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonContent className="ion-padding">
                    <ShippingProcess/>

                    <h4 className="process-name ion-text-uppercase">Delivery</h4>

                    <IonList className="shipping-list">
                        <IonItem button onClick={() => {
                            history.push('/shipping-address');
                        }} detail>
                            <IonLabel>
                                <h2>Express 19.9 $</h2>

                                <p>5 - 7 Business days</p>
                            </IonLabel>
                        </IonItem>

                        <IonItem button onClick={() => {
                        }} detail>
                            <IonLabel>
                                <h2>Express 20 $</h2>

                                <p>5 - 7 Business days</p>
                            </IonLabel>
                        </IonItem>

                        <IonItem button onClick={() => {
                        }} detail>
                            <IonLabel>
                                <h2>Express 28.9 $</h2>

                                <p>5 - 7 Business days</p>
                            </IonLabel>
                        </IonItem>

                        <IonItem button onClick={() => {
                        }} detail>
                            <IonLabel>
                                <h2>Express 19.9 $</h2>

                                <p>5 - 7 Business days</p>
                            </IonLabel>
                        </IonItem>

                        <IonItem button onClick={() => {
                        }} detail>
                            <IonLabel>
                                <h2>Express 24.9 $</h2>

                                <p>5 - 7 Business days</p>
                            </IonLabel>
                        </IonItem>
                    </IonList>
                </IonContent>
            </IonPage>
        </>
    );
};

export default ShippingType;
