import {
    IonButton,
    IonContent,
    IonHeader,
    IonIcon,
    IonImg,
    IonLabel,
    IonPage,
    IonSegment,
    IonSegmentButton,
    IonTitle,
    IonToolbar,
    IonSlide,
    IonRow,
    IonMenuButton,
    IonSearchbar,
    IonButtons,
    IonSlides,
    IonGrid,
    IonCol,
    IonCard,
    IonCardHeader,
    IonCardSubtitle,
    IonCardTitle, IonCardContent, IonBadge
} from '@ionic/react';
import ExploreContainer from '../components/ExploreContainer';
import './Home.scss';
import {bagOutline, bookmark, camera, cart, cartOutline, search, searchOutline} from "ionicons/icons";

// added useRef
import React, {useState, useRef} from 'react';
import {useHistory} from "react-router";


//import Segment from '../components/Segment';
const Home: React.FC = () => {

    let history = useHistory();

    // a ref variable to handle the current slider
    const slider = useRef<HTMLIonSlidesElement>(null);
    // a state value to bind segment value
    const [value, setValue] = useState("0");

    const slideOpts = {
        initialSlide: 1,
        speed: 400,
        loop: false,
        pagination: {
            el: null
        },

    };

    // a function to handle the segment changes
    const handleSegmentChange = (e: any) => {
        setValue(e.detail.value);
        slider.current!.slideTo(e.detail.value);
    };

    // a function to handle the slider changes
    const handleSlideChange = async (event: any) => {
        let index: number = 0;
        await event.target.getActiveIndex().then((value: any) => (index = value));
        setValue('' + index)
    };


    return (
        <IonPage>
            <IonHeader className="ion-no-border main-header">
                <IonToolbar>
                    <IonImg slot="start" src={require("../assets/images/icon/icon.png").default}/>
                    <IonButtons slot="end" onClick={()=>{
                        history.push('/cart');
                    }}>
                        <IonIcon icon={bagOutline} color="primary"/>
                        <IonBadge>3</IonBadge>
                    </IonButtons>
                </IonToolbar>

                <IonSegment value={value} onIonChange={(e) => handleSegmentChange(e)}>
                    <IonSegmentButton value="0">
                        <IonLabel>Women</IonLabel>
                    </IonSegmentButton>
                    <IonSegmentButton value="1">
                        <IonLabel>Men</IonLabel>
                    </IonSegmentButton>
                </IonSegment>

            </IonHeader>

            <IonContent>

                <IonSlides pager={true} options={slideOpts} onIonSlideDidChange={(e) => handleSlideChange(e)}
                           ref={slider}>
                    <IonSlide className="product-wrapper" onClick={()=>{
                        history.push('product-sub-category')
                    }}>
                        <div className="product-category">
                            <IonImg src={require("../assets/images/core-img/image-3.png").default}/>
                            <div className="img-overlay">
                                <h2 className="ion-text-uppercase">Socks</h2>
                            </div>
                        </div>

                        <div className="product-category">
                            <IonImg src={require("../assets/images/core-img/image-1.jpeg").default}/>
                            <div className="img-overlay">
                                <h2 className="ion-text-uppercase">New arrival</h2>
                            </div>
                        </div>

                        <div className="product-category">
                            <IonImg src={require("../assets/images/core-img/image-2.png").default}/>
                            <div className="img-overlay">
                                <h2 className="ion-text-uppercase">Trending</h2>
                            </div>
                        </div>
                    </IonSlide>

                    <IonSlide>
                        <IonSlide className="product-wrapper">
                            <div className="product-category">
                                <IonImg src={require("../assets/images/core-img/image-1.jpeg").default}/>
                                <div className="img-overlay">
                                    <h2 className="ion-text-uppercase">New in</h2>
                                </div>
                            </div>

                            <div className="product-category">
                                <IonImg src={require("../assets/images/core-img/image-2.png").default}/>
                                <div className="img-overlay">
                                    <h2 className="ion-text-uppercase">Collection</h2>
                                </div>
                            </div>

                            <div className="product-category">
                                <IonImg src={require("../assets/images/core-img/image-3.png").default}/>
                                <div className="img-overlay">
                                    <h2 className="ion-text-uppercase">Underwear</h2>
                                </div>
                            </div>
                        </IonSlide>
                    </IonSlide>
                </IonSlides>
            </IonContent>
        </IonPage>
    )
};

export default Home;
