import {
    IonBackButton, IonBadge, IonButton,
    IonButtons, IonCol,
    IonContent,
    IonGrid,
    IonHeader, IonIcon, IonImg, IonItem, IonItemOptions,
    IonItemSliding, IonLabel, IonList,
    IonPage, IonRow, IonSelect, IonSelectOption,
    IonTitle,
    IonToolbar
} from '@ionic/react';
import ExploreContainer from '../components/ExploreContainer';
import './Cart.scss';
import React, {useState} from "react";
import {bagOutline, cartOutline, trash, trashBinOutline, trashOutline} from "ionicons/icons";
import {useHistory} from "react-router-dom";

const Cart: React.FC = () => {
    let history = useHistory();

    const [quantity, setQuantity] = useState<string>();
    const options = {
        cssClass: 'custom-interface'
    };

    return (
        <IonPage>
            <IonHeader className="ion-no-border main-header">
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref=""/>
                    </IonButtons>
                    <IonTitle>SHOPPING BASKET</IonTitle>
                    <IonButtons slot="end">
                        <IonIcon icon={bagOutline} color="primary"/>
                        <IonBadge>3</IonBadge>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen>
                <IonGrid className="cart-container ion-padding">
                    <IonRow className="cart-row">
                        <IonCol size="4">
                            <IonImg src={require("../assets/images/core-img/image-6.png").default}/>
                        </IonCol>

                        <IonCol size="8" className="product-detail d-flex ion-justify-content-between">
                            <IonIcon icon={trashOutline}/>

                            <div className="top-row">
                                <h5 className="ion-text-uppercase">long 100% linen halter neck dress</h5>
                                <h6 className="ion-text-uppercase">size <span>s</span></h6>
                                <h6 className="ion-text-uppercase">Lime</h6>
                            </div>

                            <div className="bottom-row d-flex ion-justify-content-between ion-align-items-center">
                                <div className="quantity ion-align-items-center">
                                    <IonLabel className="ion-text-uppercase">Quantity</IonLabel>
                                    <IonSelect interface="action-sheet" value={quantity} placeholder="1"
                                               onIonChange={e => setQuantity(e.detail.value)}
                                               interfaceOptions={options}>
                                        <IonSelectOption value="1">1</IonSelectOption>
                                        <IonSelectOption value="2">2</IonSelectOption>
                                        <IonSelectOption value="3">3</IonSelectOption>
                                        <IonSelectOption value="4">4</IonSelectOption>
                                        <IonSelectOption value="5">5</IonSelectOption>
                                        <IonSelectOption value="6">6</IonSelectOption>
                                        <IonSelectOption value="7">7</IonSelectOption>
                                        <IonSelectOption value="8">8</IonSelectOption>
                                        <IonSelectOption value="9">9</IonSelectOption>
                                        <IonSelectOption value="10">10</IonSelectOption>
                                        <IonSelectOption value="11">11</IonSelectOption>
                                        <IonSelectOption value="12">12</IonSelectOption>
                                        <IonSelectOption value="13">13</IonSelectOption>
                                        <IonSelectOption value="14">14</IonSelectOption>
                                    </IonSelect>
                                </div>
                                <span className="product-price">$ 20.9</span>
                            </div>
                        </IonCol>
                    </IonRow>
                    <IonRow className="cart-row">
                        <IonCol size="4">
                            <IonImg src={require("../assets/images/core-img/image-7.png").default}/>
                        </IonCol>

                        <IonCol size="8" className="product-detail d-flex ion-justify-content-between">
                            <IonIcon icon={trashOutline}/>

                            <div className="top-row">
                                <h5 className="ion-text-uppercase">long 100% linen halter neck dress</h5>
                                <h6 className="ion-text-uppercase">size <span>s</span></h6>
                                <h6 className="ion-text-uppercase">Lime</h6>
                            </div>

                            <div className="bottom-row d-flex ion-justify-content-between ion-align-items-center">
                                <div className="quantity ion-align-items-center">
                                    <IonLabel className="ion-text-uppercase">Quantity</IonLabel>
                                    <IonSelect interface="action-sheet" value={quantity} placeholder="1"
                                               onIonChange={e => setQuantity(e.detail.value)}
                                               interfaceOptions={options}>
                                        <IonSelectOption value="1">1</IonSelectOption>
                                        <IonSelectOption value="2">2</IonSelectOption>
                                        <IonSelectOption value="3">3</IonSelectOption>
                                        <IonSelectOption value="4">4</IonSelectOption>
                                        <IonSelectOption value="5">5</IonSelectOption>
                                        <IonSelectOption value="6">6</IonSelectOption>
                                        <IonSelectOption value="7">7</IonSelectOption>
                                        <IonSelectOption value="8">8</IonSelectOption>
                                        <IonSelectOption value="9">9</IonSelectOption>
                                        <IonSelectOption value="10">10</IonSelectOption>
                                        <IonSelectOption value="11">11</IonSelectOption>
                                        <IonSelectOption value="12">12</IonSelectOption>
                                        <IonSelectOption value="13">13</IonSelectOption>
                                        <IonSelectOption value="14">14</IonSelectOption>
                                    </IonSelect>
                                </div>
                                <span className="product-price">$ 20.9</span>
                            </div>
                        </IonCol>
                    </IonRow>
                    <IonRow className="cart-row">
                        <IonCol size="4">
                            <IonImg src={require("../assets/images/core-img/image-8.png").default}/>
                        </IonCol>

                        <IonCol size="8" className="product-detail d-flex ion-justify-content-between">
                            <IonIcon icon={trashOutline}/>

                            <div className="top-row">
                                <h5 className="ion-text-uppercase">long 100% linen halter neck dress</h5>
                                <h6 className="ion-text-uppercase">size <span>s</span></h6>
                                <h6 className="ion-text-uppercase">Lime</h6>
                            </div>

                            <div className="bottom-row d-flex ion-justify-content-between ion-align-items-center">
                                <div className="quantity ion-align-items-center">
                                    <IonLabel className="ion-text-uppercase">Quantity</IonLabel>
                                    <IonSelect interface="action-sheet" value={quantity} placeholder="1"
                                               onIonChange={e => setQuantity(e.detail.value)}
                                               interfaceOptions={options}>
                                        <IonSelectOption value="1">1</IonSelectOption>
                                        <IonSelectOption value="2">2</IonSelectOption>
                                        <IonSelectOption value="3">3</IonSelectOption>
                                        <IonSelectOption value="4">4</IonSelectOption>
                                        <IonSelectOption value="5">5</IonSelectOption>
                                        <IonSelectOption value="6">6</IonSelectOption>
                                        <IonSelectOption value="7">7</IonSelectOption>
                                        <IonSelectOption value="8">8</IonSelectOption>
                                        <IonSelectOption value="9">9</IonSelectOption>
                                        <IonSelectOption value="10">10</IonSelectOption>
                                        <IonSelectOption value="11">11</IonSelectOption>
                                        <IonSelectOption value="12">12</IonSelectOption>
                                        <IonSelectOption value="13">13</IonSelectOption>
                                        <IonSelectOption value="14">14</IonSelectOption>
                                    </IonSelect>
                                </div>
                                <span className="product-price">$ 20.9</span>
                            </div>
                        </IonCol>
                    </IonRow>
                    <IonRow className="cart-row">
                        <IonCol size="4">
                            <IonImg src={require("../assets/images/core-img/image-9.png").default}/>
                        </IonCol>

                        <IonCol size="8" className="product-detail d-flex ion-justify-content-between">
                            <IonIcon icon={trashOutline}/>

                            <div className="top-row">
                                <h5 className="ion-text-uppercase">long 100% linen halter neck dress</h5>
                                <h6 className="ion-text-uppercase">size <span>s</span></h6>
                                <h6 className="ion-text-uppercase">Lime</h6>
                            </div>

                            <div className="bottom-row d-flex ion-justify-content-between ion-align-items-center">
                                <div className="quantity ion-align-items-center">
                                    <IonLabel className="ion-text-uppercase">Quantity</IonLabel>
                                    <IonSelect interface="action-sheet" value={quantity} placeholder="1"
                                               onIonChange={e => setQuantity(e.detail.value)}
                                               interfaceOptions={options}>
                                        <IonSelectOption value="1">1</IonSelectOption>
                                        <IonSelectOption value="2">2</IonSelectOption>
                                        <IonSelectOption value="3">3</IonSelectOption>
                                        <IonSelectOption value="4">4</IonSelectOption>
                                        <IonSelectOption value="5">5</IonSelectOption>
                                        <IonSelectOption value="6">6</IonSelectOption>
                                        <IonSelectOption value="7">7</IonSelectOption>
                                        <IonSelectOption value="8">8</IonSelectOption>
                                        <IonSelectOption value="9">9</IonSelectOption>
                                        <IonSelectOption value="10">10</IonSelectOption>
                                        <IonSelectOption value="11">11</IonSelectOption>
                                        <IonSelectOption value="12">12</IonSelectOption>
                                        <IonSelectOption value="13">13</IonSelectOption>
                                        <IonSelectOption value="14">14</IonSelectOption>
                                    </IonSelect>
                                </div>
                                <span className="product-price">$ 20.9</span>
                            </div>
                        </IonCol>
                    </IonRow>
                    <IonRow className="cart-row">
                        <IonCol size="4">
                            <IonImg src={require("../assets/images/core-img/image-5.png").default}/>
                        </IonCol>

                        <IonCol size="8" className="product-detail d-flex ion-justify-content-between">
                            <IonIcon icon={trashOutline}/>

                            <div className="top-row">
                                <h5 className="ion-text-uppercase">long 100% linen halter neck dress</h5>
                                <h6 className="ion-text-uppercase">size <span>s</span></h6>
                                <h6 className="ion-text-uppercase">Lime</h6>
                            </div>

                            <div className="bottom-row d-flex ion-justify-content-between ion-align-items-center">
                                <div className="quantity ion-align-items-center">
                                    <IonLabel className="ion-text-uppercase">Quantity</IonLabel>
                                    <IonSelect interface="action-sheet" value={quantity} placeholder="1"
                                               onIonChange={e => setQuantity(e.detail.value)}
                                               interfaceOptions={options}>
                                        <IonSelectOption value="1">1</IonSelectOption>
                                        <IonSelectOption value="2">2</IonSelectOption>
                                        <IonSelectOption value="3">3</IonSelectOption>
                                        <IonSelectOption value="4">4</IonSelectOption>
                                        <IonSelectOption value="5">5</IonSelectOption>
                                        <IonSelectOption value="6">6</IonSelectOption>
                                        <IonSelectOption value="7">7</IonSelectOption>
                                        <IonSelectOption value="8">8</IonSelectOption>
                                        <IonSelectOption value="9">9</IonSelectOption>
                                        <IonSelectOption value="10">10</IonSelectOption>
                                        <IonSelectOption value="11">11</IonSelectOption>
                                        <IonSelectOption value="12">12</IonSelectOption>
                                        <IonSelectOption value="13">13</IonSelectOption>
                                        <IonSelectOption value="14">14</IonSelectOption>
                                    </IonSelect>
                                </div>
                                <span className="product-price">$ 20.9</span>
                            </div>
                        </IonCol>
                    </IonRow>
                </IonGrid>

                <div className="cart-total ion-padding">
                    <div className="total-price ion-justify-content-between">
                        <span>TOTAL</span>
                        <span>$ 100.5</span>
                    </div>

                    <IonButton expand="block" color="primary" className="saint-btn" onClick={() => {
                        history.push('/shipping-type');
                    }}>Proceed Order</IonButton>
                </div>
            </IonContent>
        </IonPage>
    );
};

export default Cart;
