import {
    IonButtons,
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonBackButton,
    IonIcon,
    IonButton
} from '@ionic/react';

import {logoFacebook, mail, personOutline} from "ionicons/icons";
import './MyAccount.scss';
import React from "react";
import {useHistory, Link} from "react-router-dom";

const MyAccount: React.FC = () => {
    let history = useHistory();

    return (
        <IonPage>
            <IonHeader className="ion-no-border main-header">
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="home"/>
                    </IonButtons>
                    <IonTitle>MY ACCOUNT</IonTitle>
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen className="my-account-container ion-padding">
                <div className="existing-customer ion-text-center">


                    <div className="customer-type ">
                        <IonIcon icon={personOutline}/>
                        <h4>Existing Customer</h4>
                    </div>

                    <IonButton expand="block" class="saint-btn" onClick={() => {
                        history.push('/login');
                    }}>Log in</IonButton>

                    <IonButton expand="block" class="saint-btn">
                        <IonIcon icon={logoFacebook}/>
                        login with facebook
                    </IonButton>
                </div>

                <div className="new-customer ion-text-center">
                    <h4>New Customer</h4>
                    <IonButton expand="block" class="saint-btn" onClick={() => {
                        history.push('/register');
                    }}>
                        <IonIcon icon={mail}/>
                        Register
                    </IonButton>
                </div>
            </IonContent>
        </IonPage>
    );
};

export default MyAccount;
