import {
    IonBackButton, IonButton,
    IonButtons, IonCheckbox, IonCol,
    IonContent,
    IonGrid,
    IonHeader, IonIcon, IonImg, IonItem, IonItemOptions,
    IonItemSliding, IonLabel, IonList,
    IonPage, IonRow, IonSelect, IonSelectOption,
    IonTitle,
    IonToolbar
} from '@ionic/react';
import ExploreContainer from '../components/ExploreContainer';
import './Cart.scss';
import React, {useState} from "react";
import {cartOutline, trash, trashBinOutline, trashOutline} from "ionicons/icons";

const AddBundle: React.FC = () => {

    const [size, setSize] = useState<string>();
    const options = {
        cssClass: 'custom-interface'
    };
    return (
        <IonPage>
            <IonHeader className="ion-no-border main-header">
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref=""/>
                    </IonButtons>
                    <IonTitle>SAINT PERRY</IonTitle>
                    <IonIcon slot="end" icon={cartOutline} color="primary"/>
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen>
                <IonGrid className="cart-container ion-padding">
                    <IonRow className="cart-row">
                        <IonCol size="4">
                            <IonImg src={require("../assets/images/core-img/image-9.png").default}/>
                        </IonCol>

                        <IonCol size="8" className="product-detail">
                            <IonCheckbox/>
                            <h5 className="ion-text-uppercase">long 100% linen halter neck dress</h5>
                            <span className="product-price">$ 20.9</span>

                            <div className="bottom-row ion-justify-content-between ion-align-items-center">
                                <div className="size ion-align-items-center">
                                    <IonLabel className="ion-text-uppercase">Size</IonLabel>

                                    <IonSelect interface="action-sheet" value={size} placeholder=""
                                               onIonChange={e => setSize(e.detail.value)}
                                               interfaceOptions={options}>
                                        <IonSelectOption value="XS">XS</IonSelectOption>
                                        <IonSelectOption value="S">S</IonSelectOption>
                                        <IonSelectOption value="L">L</IonSelectOption>
                                        <IonSelectOption value="M">M</IonSelectOption>
                                        <IonSelectOption value="XL">XL</IonSelectOption>
                                        <IonSelectOption value="XXL">XXL</IonSelectOption>
                                    </IonSelect>
                                </div>
                            </div>
                        </IonCol>
                    </IonRow>
                    <IonRow className="cart-row">
                        <IonCol size="4">
                            <IonImg src={require("../assets/images/core-img/image-8.png").default}/>
                        </IonCol>

                        <IonCol size="8" className="product-detail">
                            <IonCheckbox/>
                            <h5 className="ion-text-uppercase">long 100% linen halter neck dress</h5>
                            <span className="product-price">$ 20.9</span>

                            <div className="bottom-row ion-justify-content-between ion-align-items-center">
                                <div className="size ion-align-items-center">
                                    <IonLabel className="ion-text-uppercase">Size</IonLabel>

                                    <IonSelect interface="action-sheet" value={size} placeholder=""
                                               onIonChange={e => setSize(e.detail.value)}
                                               interfaceOptions={options}>
                                        <IonSelectOption value="XS">XS</IonSelectOption>
                                        <IonSelectOption value="S">S</IonSelectOption>
                                        <IonSelectOption value="L">L</IonSelectOption>
                                        <IonSelectOption value="M">M</IonSelectOption>
                                        <IonSelectOption value="XL">XL</IonSelectOption>
                                        <IonSelectOption value="XXL">XXL</IonSelectOption>
                                    </IonSelect>
                                </div>
                            </div>
                        </IonCol>
                    </IonRow>
                    <IonRow className="cart-row">
                        <IonCol size="4">
                            <IonImg src={require("../assets/images/core-img/image-4.png").default}/>
                        </IonCol>

                        <IonCol size="8" className="product-detail">
                            <IonCheckbox/>
                            <h5 className="ion-text-uppercase">long 100% linen halter neck dress</h5>
                            <span className="product-price">$ 20.9</span>

                            <div className="bottom-row ion-justify-content-between ion-align-items-center">
                                <div className="size ion-align-items-center">
                                    <IonLabel className="ion-text-uppercase">Size</IonLabel>

                                    <IonSelect interface="action-sheet" value={size} placeholder=""
                                               onIonChange={e => setSize(e.detail.value)}
                                               interfaceOptions={options}>
                                        <IonSelectOption value="XS">XS</IonSelectOption>
                                        <IonSelectOption value="S">S</IonSelectOption>
                                        <IonSelectOption value="L">L</IonSelectOption>
                                        <IonSelectOption value="M">M</IonSelectOption>
                                        <IonSelectOption value="XL">XL</IonSelectOption>
                                        <IonSelectOption value="XXL">XXL</IonSelectOption>
                                    </IonSelect>
                                </div>
                            </div>
                        </IonCol>
                    </IonRow>
                    <IonRow className="cart-row">
                        <IonCol size="4">
                            <IonImg src={require("../assets/images/core-img/image-10.png").default}/>
                        </IonCol>

                        <IonCol size="8" className="product-detail">
                            <IonCheckbox/>
                            <h5 className="ion-text-uppercase">long 100% linen halter neck dress</h5>
                            <span className="product-price">$ 20.9</span>

                            <div className="bottom-row ion-justify-content-between ion-align-items-center">
                                <div className="size ion-align-items-center">
                                    <IonLabel className="ion-text-uppercase">Size</IonLabel>

                                    <IonSelect interface="action-sheet" value={size} placeholder=""
                                               onIonChange={e => setSize(e.detail.value)}
                                               interfaceOptions={options}>
                                        <IonSelectOption value="XS">XS</IonSelectOption>
                                        <IonSelectOption value="S">S</IonSelectOption>
                                        <IonSelectOption value="L">L</IonSelectOption>
                                        <IonSelectOption value="M">M</IonSelectOption>
                                        <IonSelectOption value="XL">XL</IonSelectOption>
                                        <IonSelectOption value="XXL">XXL</IonSelectOption>
                                    </IonSelect>
                                </div>
                            </div>
                        </IonCol>
                    </IonRow>
                </IonGrid>

                <div className="cart-total ion-padding">
                    <div className="total-price ion-justify-content-between">
                        <span>TOTAL</span>
                        <span>$ 100.5</span>
                    </div>

                    <IonButton expand="block" color="primary" className="saint-btn">add to basket</IonButton>
                </div>
            </IonContent>
        </IonPage>
    );
};

export default AddBundle;
